import React, { useState } from "react";
import Layout from "../core/Layout";
import { Redirect, useHistory  } from 'react-router-dom';
import { signin, authenticate, isAuthenticated } from '../auth';
import { Formik, Form } from 'formik'

// import { Link } from "react-router-dom";
import spark from '../assets/spark.png';
import { Link, Button, Card, CardContent, Divider, IconButton, Stack, TextField, Typography } from "@mui/material";

import CollapseAlert from "../core/CollapseAlert"

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const Signin = () => {
    const history = useHistory();

    const [values, setValues] = useState({
        email: "",
        password: "",
        error: "",
        loading: false,
        redirectToReferrer: false
    });
    const [loggedIn, setLoggedIn] = useState(false);
    const [apiKeys, setApiKeys] = useState("");
    const [documentation, setDocumentation] = useState("");

    const { email, password, loading, error, redirectToReferrer } = values;

    const { user } = isAuthenticated();

    const [alertState, setAlertState] = useState({
        open: false,
        severity: "success",
        message: "Message"
    })

    const [showPassword, setShowPassword] = useState(false)

    const signInForm = () => (
        <Formik
            initialValues={{
                email: "",
                password: "",
            }}
            onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
    
                // Hardcoded check for credentials
                if(data.email === "bayadirectclient@gmail.com" && data.password === "eSpApGtO6N") {
                    console.log("Logged in");
                    //history.push('/dashboard')
                    setValues({
                        ...values,
                        redirectToReferrer: true
                    });

                    setLoggedIn(true);
                    setApiKeys("STAGING: "); // Fill this with the actual API keys
                    setDocumentation("Your documentation"); // Fill this with the actual documentation
                } else {
                    setAlertState({
                        open: true,
                        severity: "error",
                        message: "Invalid credentials"
                    });
                }
    
                setSubmitting(false);
            }}
        >
            {
                ({ handleChange, isSubmitting }) => {
                    return (
                        <Form>
                            <Stack spacing={2} textAlign={"center"}>
                                <Stack direction={"row"} justifyContent={"center"}>
                                    <img
                                        className="img-fluid bayadirect"
                                        src="https://sparkle-vortex.imgix.net/bayadirect.jpg?w=300&h=300"
                                        alt="company-logo"
                                        style={{
                                            objectFit: "contain"
                                        }} />
                                </Stack>
                                <Typography fontWeight={"bold"} fontSize={25}>Log in</Typography>
                                <Typography fontSize={14} sx={{ color: "gray" }}>Sign in on the internal platform</Typography>
                                <TextField
                                    required
                                    name={"email"}
                                    type={"email"}
                                    label={"Email Address"}
                                    placeholder={"Enter email address"}
                                    onChange={handleChange}
                                />
                                <TextField
                                    required
                                    name={"password"}
                                    type={showPassword ? "text" : "password"}
                                    label={"Password"}
                                    placeholder={"Enter password"}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={() => {
                                                    setShowPassword(!showPassword)
                                                }}
                                            >
                                                {showPassword ? (
                                                    <VisibilityIcon />
                                                ) : (
                                                    <VisibilityOffIcon />
                                                )}
                                            </IconButton>
                                        ),
                                    }}
                                />
                                <CollapseAlert
                                    open={alertState.open}
                                    severity={alertState.severity}
                                    message={alertState.message}
                                    onClose={() => {
                                        setAlertState((prevState) => ({ ...prevState, open: false }))
                                    }}
                                />
                                <Divider />
                                <Button disabled={isSubmitting} variant={"contained"} type={"submit"} sx={{ height: 50 }}>{isSubmitting ? "Please wait..." : "SIGN IN"}</Button>

                            </Stack>
                        </Form>
                    )
                }
            }
        </Formik>
    );

    const redirectUser = () => {
        if (redirectToReferrer) {
            return <Redirect to='/admin/dashboard' />
        }
    }
    

    return (
        <Layout>
            <div className="container">
                <div className="hv-100 d-flex align-items-center justify-content-center login__container">
                    <div className="w-50 card_login-content">
                        <h2 className="text-center">Welcome to  <br /> Sandbox <br />BayaDirect!</h2>
                        <img src={spark} className="img-fluid spark_img" alt="sparkles" draggable="false" />
                    </div>
                    <div className="w-50 h-50 m-50 d-flex align-items-center justify-content-center">
                        <Card elevation={10} sx={{ minWidth: 300, maxWidth: 500, width: 500, marginTop: 20, paddingBottom: 5 }}>
                            <CardContent sx={{ padding: 5 }}>
                                {loggedIn ?
                                  <>
                                  <Typography fontWeight={"bold"} fontSize={25} sx={{textAlign: "center", width:"100%"}}>API Credentials</Typography>
                                    {/* <h2></h2> */}
                                    {/* <p>Staging</p> */}
                                    <TextField
                                        id="filled-read-only-input"
                                        label="Staging Client Key"
                                        defaultValue="Tw9K28BRxBrDGGg0pRgPiKT8rVYa"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ width: "100%"}}
                                        variant="filled"
                                        />
                                    <TextField
                                        id="filled-read-only-input"
                                        label="Staging Secret Key"
                                        defaultValue="1Yp7ftinlsiFuY_1vSCBOJl4zrYa"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ width: "100%"}}
                                        variant="filled"
                                        />
                                    <TextField
                                        id="filled-read-only-input"
                                        label="Production Client Key"
                                        defaultValue="zkgebj9slaf3yojw8cgk"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ width: "100%", marginTop: "1em"}}
                                        variant="filled"
                                        />
                                    <TextField
                                        id="filled-read-only-input"
                                        label="Production Secret Key"
                                        defaultValue="ysdcwh57ydkj3toqsq5mbk93qnafp7bib5r4fouz"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ width: "100%"}}
                                        variant="filled"
                                        />

<Link href="/documentation" target="_blank" rel="noopener noreferrer" underline="none" >
        <Button variant="contained" color="primary" endIcon={<OpenInNewIcon />} sx={{ height: 50, width: "100%", marginTop: "1em", opacity:0.5 }}> 
            Documentation
        </Button>
    </Link>
                                    {/* <h2>Documentation:</h2>
                                    <p>{documentation}</p> */}
                                    <Button onClick={() => setLoggedIn(false)} variant={"contained"} type={"submit"} sx={{ height: 50, width: "100%", marginTop:"5em" }}>SIGN OUT</Button>
                                    {/* <button onClick={() => setLoggedIn(false)}>Sign Out</button> */}
                                  </> : 
                                  signInForm()
                                }
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Signin;



