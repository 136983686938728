import React from 'react'
import DashboardContext from './DashboardContext/DashboardContext'


const MainContext = ({ children }) => {

  return (
    <DashboardContext>{children}</DashboardContext>
  )

}

export default MainContext