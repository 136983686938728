
import React, { useState } from 'react'

export const PlatformVariables = React.createContext({});

const DashboardContext = ({ children }) => {

  const [platformVariables, setPlatformVariables] = useState({})


  return (
    <PlatformVariables.Provider value={[platformVariables, setPlatformVariables]}>{children}</PlatformVariables.Provider>
  )
}

export default DashboardContext