import React from 'react'
import StoresContext from './context/StoresContext';

import Paperbase from './Paperbase';

const StoresMainPage = () => {



  return (
    <StoresContext>
      <Paperbase />
    </StoresContext>
  )
}

export default StoresMainPage