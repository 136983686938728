import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Signup from "./user/Signup";
import Signin from "./user/Signin";
import SignInMockUp from "./user/SignInMockUp";
import AdminDashboard from "./user/AdminDashboard";
import Dashboard from "./user/DashboardMockUp";
import PrivateRoute from "./auth/PrivateRoute";
import AdminRoute from "./auth/AdminRoute";
import NewPassword from "./core/Newpassword";
import Home from "./core/Home";
import Profile from "./user/Profile";
import BroadcastMainPage from "./admin/broadcast/BroadcastMainPage";
import UserDirectoryMainPage from "./admin/userdirectory/UserDirectoryMainPage";
import VortexMainPage from "./admin/vortex/VortexMainPage";
import StoresMainPage from "./admin/stores/StoresMainPage";
import MySwaggerComponent from "./user/Swagger";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* <PrivateRoute path='/' exact component={AdminDashboard} /> */}
        <Route path='/' exact component={Home} />
        <Route path='/signin' exact component={Signin} />
        <Route path='/documentation' exact component={MySwaggerComponent} />
        {/* <Route path='/signup' exact component={Signup} />
        <Route path='/reset/:token' exact component={NewPassword} />
        <AdminRoute path='/admin/dashboard' exact component={AdminDashboard} />
        <AdminRoute
          path='/admin/broadcasts'
          exact
          component={BroadcastMainPage}
        />
        <AdminRoute path='/admin/stores' exact component={StoresMainPage} />
        <AdminRoute
          path='/admin/userdirectory'
          exact
          component={UserDirectoryMainPage}
        />

        <AdminRoute
          path='/admin/vortex'
          exact
          component={VortexMainPage}
        />

        <PrivateRoute path='/profile/:userId' exact component={Profile} /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
