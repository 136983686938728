import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import openapi from '../assets/openapi.json';

function MySwaggerComponent() {
  return (
    <SwaggerUI spec={openapi} />
  );
}

export default MySwaggerComponent;
