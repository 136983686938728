import { API } from "../config";

export const signup = (userObj) => {
  return fetch(
    `${process.env.REACT_APP_API_URL ? `${API}` : "/api"}/auth/signup`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userObj),
    }
  )
    .then(async (response) => {
      if (response.status !== 200) {
        let jsonData = await response.json();
        throw Error(JSON.stringify(jsonData));
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

export const signin = (userObj) => {
  return fetch(
    `${process.env.REACT_APP_API_URL ? `${API}` : "/api"}/auth/signin`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userObj),
    }
  )
    .then(async (response) => {
      if (response.status !== 200) {
        let jsondata = await response.json();
        throw Error(JSON.stringify(jsondata));
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

export const authenticate = (data, next) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("jwt", JSON.stringify(data));
    next();
  }
};

export const signout = (next) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("jwt");
    next();
    return fetch(
      `${process.env.REACT_APP_API_URL ? `${API}` : "/api"}/auth/signout`,
      {
        method: "GET",
      }
    )
      .then((response) => {
        console.log("signout", response);
      })
      .catch((err) => console.log(err));
  }
};

export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("jwt")) {
    return JSON.parse(localStorage.getItem("jwt")); // prior: true || this JSON is here because we need to fetch the data to populate userDashboard
  } else {
    return false;
  }
};

export const requestPIN = () => {
  const { token } = JSON.parse(localStorage.getItem("jwt"));

  return fetch(`${process.env.REACT_APP_API_URL}/auth/request-pin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(async (response) => {
      if (response.status !== 200) {
        let responseData = await response.json();
        let jsondata = {
          message: responseData?.error,
        };
        throw Error(JSON.stringify(jsondata));
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

export const confirmPIN = ({ pin }) => {
  const { token } = JSON.parse(localStorage.getItem("jwt"));

  return fetch(`${process.env.REACT_APP_API_URL}/auth/confirm-pin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      pin: pin,
    }),
  })
    .then(async (response) => {
      if (response.status !== 200) {
        let jsondata = await response.json();
        throw Error(JSON.stringify(jsondata));
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};
